import React from 'react';
import '../../scss/page/Business.scss';

const SuppliyStore = () => {
  return (
    <div className='suppliyStore'>
      <div className='textBox'>
        <h5>각종 선용품 보급과 선적대행업무를 수행합니다.</h5>
        <span>
          고객이 희망하는 선용품을 구매, 선적 대행
          <br />- 선용품은 일반적으로 선박에서 사용하는 물건의 총칭입니다.
          선박용 물건이라 표현하기도 하며, 사전적 의미로는 선박에서 사용되는
          용품으로 정의 할 수 있습니다. 다만, 선박은 화물선, 여객선 등 그 용도에
          따라 다양한 물품을 사용하고 있고, 새로운 물품이 생산/공급되고 있기
          때문에 선용품 여부에 대해 하나하나 개별적으로 정의하기는 어렵습니다.
        </span>
      </div>
      <div className='videoBox'>
        <video
          id='contents-video'
          poster='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/SuppliyShip.mp4'
          preload='auto'
          loop
          autoPlay
          muted>
          <source
            src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/SuppliyShip.mp4'
            type='video/mp4'
          />
        </video>
      </div>
    </div>
  );
};

export default SuppliyStore;
