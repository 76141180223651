import React from 'react';
import ContentTitle from '../components/ContentTitle';
import { useLocation } from 'react-router-dom';

const RefuseEmail = () => {
  const path = useLocation().pathname;
  return (
    <section className='refuseemail pagemargin container'>
      <ContentTitle
        title={
          path.includes('/eng')
            ? 'Refuse Collect E-mail'
            : '이메일 무단수집거부'
        }
      />
      <div className='refuseemail__contents'>
        {path.includes('/eng') ? (
          <>
            <p>
              This website refuses the unauthorized collection of email
              addresses through email harvesting programs or other technological
              devices. <br />
              Violating this may result in penalties under 『The Act on
              Promotion of Information and Communication Network Utilization and
              Information Protection』, Etc.
            </p>
            <h4>
              &lt;Act on Promotion of Information and Communication Network
              Utilization and Information Protection, Etc.&gt;
            </h4>

            <p>
              Article 50-2 (Prohibition of Unauthorized Collection of Email
              Addresses and Related Acts)
            </p>
            <ul>
              <li>
                No one shall collect email addresses automatically from an
                internet website using programs or other technological devices
                without prior consent from the operator or administrator of the
                internet website.
              </li>
              <li>
                No one shall sell or distribute email addresses collected in
                violation of the provisions of paragraph 1.
              </li>
              <li>
                No one shall knowingly use email addresses collected, sold, or
                distributed in violation of the provisions of paragraphs 1 and 2
                for information transmission.
              </li>
            </ul>

            <p>
              Article 74 (Penalties) The following persons shall be subject to a
              fine of up to ten million won:
            </p>
            <ul>
              <li>
                Those who violate the provisions of Article 8, paragraph 4, by
                displaying, selling, or displaying for the purpose of selling
              </li>
              <li>
                Those who violate the provisions of Article 44-7, paragraph 1,
                subparagraph 1, by distributing, selling, leasing, or publicly
                exhibiting obscene signs, words, sounds, images, or videos
              </li>
              <li>
                Those who violate the provisions of Article 44-7, paragraph 1,
                subparagraph 3, by repeatedly transmitting signs, words, sounds,
                images, or videos that induce fear or anxiety to the other party
              </li>
              <li>
                Those who violate the provisions of Article 50, paragraph 6, by
                taking technical measures
              </li>
              <li>
                Those who violate the provisions of Article 50-8 by transmitting
                advertising information
              </li>
              <li>
                Those who violate the provisions of Article 50-2 by collecting,
                selling, distributing, or using email addresses for information
                transmission
              </li>
              <li>
                Those who violate the provisions of Article 50-8 by transmitting
                advertising information
              </li>
              <li>
                Those who violate the provisions of Article 53, paragraph 4, by
                failing to report changes in registration information, transfer,
                acquisition, merger, or inheritance of business.
              </li>
            </ul>
          </>
        ) : (
          <>
            <p>
              본 웹사이트는 게시된 이메일 주소가 전자우편 수집 프로그램이나 그
              밖의 기술적 장치를 이용하여 무단 수집되는 것을 거부합니다. <br />
              이를 위반시 『정보통신망 이용촉진 및 정보보호등에 관한 법률』등에
              의해 처벌 받을 수 있습니다.
            </p>
            <h4>&lt;정보통신망 이용촉진 및 정보보호 등에 관한 법률&gt;</h4>

            <p>제50조의2 (전자우편주소의 무단 수집행위 등 금지)</p>
            <ul>
              <li>
                누구든지 인터넷 홈페이지 운영자 또는 관리자의 사전 동의 없이
                인터넷 홈페이지에서 자동으로 전자우편주소를 수집 하는 프로그램
                그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는
                아니된다.
              </li>
              <li>
                누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를
                판매ㆍ유통하여서는 아니된다.
              </li>
              <li>
                누구든지 제1항 및 제2항의 규정에 의하여 수집ㆍ판매 및 유통이
                금지된 전자우편주소임을 알고 이를 정보 전송에 이용하여서는
                아니된다.
              </li>
            </ul>

            <p>
              제74조 (벌칙) 다음 각호의 1에 해당하는 자는 1천만원 이하의 벌금에
              처한다.
            </p>
            <ul>
              <li>
                제8조제4항의 규정을 위반하여 표시ㆍ판매 또는 판매할 목적으로
                진열한 자
              </li>
              <li>
                제44조의7제1항제1호의 규정을 위반하여 음란한
                부호ㆍ문언ㆍ음향ㆍ화상 또는 영상을 배포ㆍ판매ㆍ임대하거나 공연히
                전시한 자
              </li>
              <li>
                제44조의7제1항제3호의 규정을 위반하여 공포심이나 불안감을
                유발하는 부호ㆍ문언ㆍ음향ㆍ화상 또는 영상을 반복적으로
                상대방에게 도달하게 한 자
              </li>
              <li>제50조제6항의 규정을 위반하여 기술적 조치를 한 자</li>
              <li>제50조의8의 규정을 위반하여 광고성 정보를 전송한 자 </li>
              <li>
                제50조의2의 규정을 위반하여 전자우편 주소를 수집ㆍ판매ㆍ유통
                또는 정보전송에 이용한 자
              </li>
              <li>제50조의8의 규정을 위반하여 광고성 정보를 전송한 자</li>
              <li>
                제53조제4항을 위반하여 등록사항의 변경등록 또는 사업의
                양도ㆍ양수 또는 합병ㆍ상속의 신고를 하지 아니한 자
              </li>
            </ul>
          </>
        )}
      </div>
    </section>
  );
};

export default RefuseEmail;
