import React from 'react';
import ContentTitle from '../components/ContentTitle';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {
  const path = useLocation().pathname;

  return (
    <section className='privacypolicy pagemargin container'>
      <ContentTitle
        title={path.includes('/eng') ? 'Privacy Policy' : '개인정보처리방침'}
      />
      <div className='privacypolicy__contents'>
        {path.includes('/eng') ? (
          <>
            <div>
              <p>
                S DENET Co., Ltd. (hereinafter referred to as "S DENET")
                establishes and discloses this Privacy Policy to protect the
                personal information of data subjects and to promptly and
                smoothly handle complaints related to such information in
                accordance with Article 30 of the Personal Information
                Protection Act.
              </p>

              <p>○ This Privacy Policy is effective from January 1, 2023.</p>
            </div>
            <div>
              <p>Article 1 (Purpose of Personal Information Processing)</p>
              <br />
              S DENET Co., Ltd. (hereinafter referred to as "S DENET") processes
              personal information for the following purposes. The processed
              personal information will not be used for purposes other than the
              following, and if the purpose of use is changed, separate consent
              will be obtained in accordance with Article 18 of the Personal
              Information Protection Act.
              <br />
              <br />
              1. Use in marketing and advertising <br />
              Personal information is processed for purposes such as
              understanding access frequency or statistics regarding members'
              service usage.
            </div>
            <div>
              <p>
                Article 2 (Processing and Retention Period of Personal
                Information)
              </p>
              <br />① S DENET Co., Ltd. processes and retains personal
              information within the period of retention and use of personal
              information according to relevant laws or within the period agreed
              upon when collecting personal information from data subjects.{' '}
              <br />② The processing and retention periods for each personal
              information are as follows:
              <br />
              Personal information related to item 1 will be retained and used
              for the above purpose until the consent for collection and use is
              obtained. <br />
              Basis for retention: service provision <br />
              Relevant laws: regarding display/advertisement <br />
              record period : 6 months <br />
              Exception :
            </div>
            <div>
              <p>Article 3 (Items of Processed Personal Information)</p>
              <br /> ① S DENET Co., Ltd. processes the following personal
              information items. <br />
              1. Use in marketing and advertising Mandatory items: Access IP
              information, cookies, access logs, service usage records
              <br /> Optional items:
            </div>
            <div>
              <p>
                Article 4 (Procedure and Method of Personal Information
                Destruction)
              </p>
              <br /> ① S DENET Co., Ltd. promptly destroys personal information
              when it becomes unnecessary due to the expiration of the retention
              period of personal information, achievement of the processing
              purpose, etc. <br /> ② If the period of retaining personal
              information agreed upon with the data subject has expired or if
              the processing purpose has been achieved, but personal information
              must be retained under other laws, the personal information will
              be transferred to a separate database (DB) or stored in a
              different location to be retained.
              <br /> 1. Basis for retention: <br /> 2. Personal information
              items being retained: Account information, transaction dates
              <br /> ③ The procedure and method of personal information
              destruction are as follows <br /> 1. Destruction procedure: S
              DENET Co., Ltd. selects personal information that needs to be
              destroyed, obtains approval from the personal information
              protection manager of S DENET Co., Ltd., and then destroys the
              personal information. <br /> Destruction method: For
              electronically stored information, technical methods that make the
              records irreproducible are used.
            </div>
            <div>
              <p>
                Article 5 (Matters concerning the Rights and Obligations of Data
                Subjects and Legal Representatives and Their Exercise Methods)
              </p>
              <br />① Data subjects can exercise rights such as accessing,
              correcting, deleting, and requesting the suspension of personal
              information processing from S DENET Co., Ltd. at any time.
              <br /> ② The exercise of rights under Paragraph 1 can be done in
              writing, by electronic mail, facsimile, etc., in accordance with
              Article 41(1) of the Enforcement Decree of the Personal
              Information Protection Act, and S DENET Co., Ltd. will take action
              without delay. <br />③ The exercise of rights under Paragraph 1
              can also be done through an agent such as a legal representative
              or a delegate. In this case, the agent must submit a power of
              attorney in accordance with Annex No. 11 of the Guidelines for
              Personal Information Processing Methods (No. 2020-7). <br />④ The
              right to access and request the suspension of processing can be
              limited under Article 35(4) and Article 37(2) of the Personal
              Information Protection Act.
              <br /> ⑤ The request for correction and deletion of personal
              information cannot be made if the personal information is
              specified as the collection target in other laws.
              <br /> ⑥ S DENET Co., Ltd. confirms whether the requester of
              access, correction, deletion, or processing suspension is the
              individual in question or a legitimate representative when
              responding to requests for access in accordance with the rights of
              data subjects.
            </div>
            <div>
              <p>
                Article 6 (Measures for Ensuring the Security of Personal
                Information)
              </p>
              <br />
              S DENET Co., Ltd. takes the following measures to ensure the
              security of personal information: <br />
              1. Minimization and education of personal information handlers S
              DENET Co., Ltd. designates personnel to handle personal
              information and limits them to designated individuals to minimize
              and manage personal information.
              <br /> 2. Retention and prevention of tampering of access records
              Access records of the personal information processing system are
              retained and managed for at least one year. However, for cases
              involving more than 50,000 data subjects, or cases involving the
              processing of unique identifiers or sensitive information, the
              records are retained and managed for at least two years. Security
              features are used to prevent tampering, theft, or loss of access
              records.
              <br /> 3. Access control for unauthorized persons A separate
              physical storage location is designated for storing personal
              information, and access control procedures are established and
              operated.
            </div>
            <div>
              <p>
                Article 7 (Installation, Operation, and Rejection of Devices for
                Automatic Collection of Personal Information)
              </p>
              <br />① S DENET Co., Ltd. uses 'cookies' to provide individualized
              customized services by storing and retrieving usage information
              from users for the operation of websites.
              <br />② Cookies are small pieces of information sent by the server
              (http) operating the website to the user's computer browser and
              are sometimes stored on the user's PC computer's hard drive.{' '}
              <br />
              a. Purpose of using cookies: Cookies are used to understand visit
              and usage patterns for each service and website visited by users,
              popular search terms, secure connections, etc., in order to
              provide optimized information to users.
              <br /> b. Installation, operation, and rejection of cookies: Users
              can reject cookie storage through the option settings in the
              Tools-Internet Options-Privacy menu of their web browser.
              <br />
              c. Refusing cookie storage may make it difficult to use customized
              services.
            </div>
            <div>
              <p>
                Article 8 (Matters concerning the Person in Charge of Personal
                Information Protection)
              </p>
              <br /> ① S DENET Co., Ltd. is responsible for overall personal
              information processing and has designated the following person in
              charge of personal information protection to handle complaints and
              remedies related to personal information processing. <br />
              ▶ Person in charge of personal information protection <br />
              Name: Jeon Hoon Taek
              <br />
              Position: CEO <br />
              Title: CEO
              <br />
              Contact: 070-4220-8898, info@sdenet.net, 0507-087-8898 <br />
              ※ This person is also connected to the department responsible for
              personal information protection.
              <br />▶ Department responsible for personal information protection{' '}
              <br />
              Department: Digital Convergence Team <br />
              Person in charge: Jeon Hoon Taek <br />
              Contact: 070-0420-8898, info@sdenet.net, 0507-087-8898
              <br /> ② Data subjects can contact the person in charge of
              personal information protection and the relevant department to
              inquire about all matters related to personal information
              protection, including inquiries, complaints, and remedies, while
              using the services (or business) of S DENET Co., Ltd. S DENET Co.,
              Ltd. will respond and handle data subjects' inquiries promptly.
            </div>
            <div>
              <p>
                Article 9 (Department for Receiving and Processing Requests for
                Access to Personal Information)
              </p>
              <br /> Data subjects can submit requests for access to personal
              information under Article 35 of the Personal Information
              Protection Act to the following department. <br />
              S DENET Co., Ltd. will make efforts to promptly process requests
              for access to personal information by data subjects. <br />
              ▶Department for Receiving and Processing Requests for Access to
              Personal Information <br />
              Department: Digital Convergence Team <br />
              Person in charge: Jeon Hoon Taek <br />
              Contact: 070-4220-8898, info@sdenet.net, 0507-087-8898
            </div>
            <div>
              <p>
                Article 10 (Remedies for Infringement of Rights of Data
                Subjects)
              </p>
              <br />
              Data subjects can apply for dispute resolution, consultations,
              etc., to the Personal Information Dispute Resolution Committee,
              the Korea Internet & Security Agency Personal Information
              Infringement Reporting Center, etc., to seek remedies for
              infringements of their rights due to personal information
              breaches. <br />
              For reporting and consultation on other personal information
              breaches, please contact the following organizations: <br />
              1. Personal Information Dispute Resolution Committee: 1833-6972
              (without area code) (www.kopico.go.kr) <br />
              2. Personal Information Infringement Reporting Center: 118
              (without area code) (privacy.kisa.or.kr)
              <br /> 3. Supreme Prosecutors' Office: 1301 (without area code)
              (www.spo.go.kr) <br />
              4. National Police Agency: 182 (without area code)
              (ecrm.cyber.go.kr) In cases where a person's rights or interests
              have been infringed due to a disposition by a public institution's
              head or due to an unauthorized act in response to a demand under
              the provisions of Article 35 (Access to Personal Information),
              Article 36 (Correction and Deletion of Personal Information), or
              Article 37 (Suspension of Processing Personal Information) of the
              "Personal Information Protection Act," the aggrieved person may
              request administrative litigation in accordance with the
              Administrative Litigation Act. <br />※ For detailed information
              about administrative litigation, please refer to the website of
              the Central Administrative Litigation Committee
              (www.simpan.go.kr).
            </div>
            <div>
              <p>Article 11 (Changes to the Privacy Policy)</p> <br />
              ① This Privacy Policy is effective from January 1, 2023.
              <br />② The previous Privacy Policy can be confirmed below.
            </div>
          </>
        ) : (
          <>
            <div>
              <p>
                에스데넷 주식회사(&apos;https://sdenet.kr&apos;이하 &apos;S
                DENET&apos;)은(는) 「개인정보 보호법」 제30조에 따라 정보주체의
                개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할
                수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
                수립·공개합니다.
              </p>

              <p>○ 이 개인정보처리방침은 2023년 1월 1부터 적용됩니다.</p>
            </div>
            <div>
              <p>제1조(개인정보의 처리 목적)</p> <br />
              에스데넷 주식회사('https://sdenet.kr'이하 'S DENET')은(는) 다음의
              목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는
              다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는
              경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등
              필요한 조치를 이행할 예정입니다. <br />
              <br />
              1. 마케팅 및 광고에의 활용 <br />
              접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로
              개인정보를 처리합니다.
            </div>
            <div>
              <p>제2조(개인정보의 처리 및 보유 기간)</p>
              <br />① 에스데넷 주식회사은(는) 법령에 따른 개인정보 보유·이용기간
              또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보
              보유·이용기간 내에서 개인정보를 처리·보유합니다. <br />② 각각의
              개인정보 처리 및 보유 기간은 다음과 같습니다.
              <br /> 1 와 관련한 개인정보는 수집.이용에 관한 동의일로부터까지 위
              이용목적을 위하여 보유.이용됩니다. <br />
              보유근거 : 서비스 제공 <br />
              관련법령 : 표시/광고에 관한 <br />
              기록 : 6개월 <br />
              예외사유 :
            </div>
            <div>
              <p>제3조(처리하는 개인정보의 항목)</p>
              <br /> ① 에스데넷 주식회사은(는) 다음의 개인정보 항목을 처리하고
              있습니다. <br />
              1. 마케팅 및 광고에의 활용 <br />
              필수항목 : 접속 IP 정보, 쿠키, 접속 로그, 서비스 이용 기록
              <br />
              선택항목 :
            </div>
            <div>
              <p>제4조(개인정보의 파기절차 및 파기방법)</p>
              <br /> ① 에스데넷 주식회사 은(는) 개인정보 보유기간의 경과,
              처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당
              개인정보를 파기합니다. <br /> ② 정보주체로부터 동의받은 개인정보
              보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른
              법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당
              개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여
              보존합니다.
              <br /> 1. 법령 근거 : <br /> 2. 보존하는 개인정보 항목 : 계좌정보,
              거래날짜 <br /> ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.{' '}
              <br /> 1. 파기절차 에스데넷 주식회사 은(는) 파기 사유가 발생한
              개인정보를 선정하고, 에스데넷 주식회사 의 개인정보 보호책임자의
              승인을 받아 개인정보를 파기합니다. <br /> 2. 파기방법 전자적 파일
              형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다
            </div>
            <div>
              <p>
                제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한
                사항)
              </p>
              <br />① 정보주체는 에스데넷 주식회사에 대해 언제든지 개인정보
              열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
              <br /> ② 제1항에 따른 권리 행사는에스데넷 주식회사에 대해
              「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편,
              모사전송(FAX) 등을 통하여 하실 수 있으며 에스데넷 주식회사은(는)
              이에 대해 지체 없이 조치하겠습니다. <br />③ 제1항에 따른 권리
              행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여
              하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한
              고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야
              합니다. <br />④ 개인정보 열람 및 처리정지 요구는 「개인정보
              보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가
              제한 될 수 있습니다.
              <br /> ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그
              개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할
              수 없습니다.
              <br /> ⑥ 에스데넷 주식회사은(는) 정보주체 권리에 따른 열람의 요구,
              정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가
              본인이거나 정당한 대리인인지를 확인합니다.
            </div>
            <div>
              <p>제6조(개인정보의 안전성 확보조치에 관한 사항)</p>
              <br />
              에스데넷 주식회사은(는) 개인정보의 안전성 확보를 위해 다음과 같은
              조치를 취하고 있습니다. <br />
              1. 개인정보 취급 직원의 최소화 및 교육 개인정보를 취급하는 직원을
              지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을
              시행하고 있습니다.
              <br /> 2. 접속기록의 보관 및 위변조 방지 개인정보처리시스템에
              접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명
              이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는
              민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.
              또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을
              사용하고 있습니다.
              <br /> 3. 비인가자에 대한 출입 통제 개인정보를 보관하고 있는
              물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립,
              운영하고 있습니다.
            </div>
            <div>
              <p>
                제7조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에
                관한 사항)
              </p>
              <br />① 에스데넷 주식회사 은(는) 이용자에게 개별적인 맞춤서비스를
              제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
              사용합니다. <br />② 쿠키는 웹사이트를 운영하는데 이용되는
              서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며
              이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. <br />
              가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에
              대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여
              이용자에게 최적화된 정보 제공을 위해 사용됩니다.
              <br /> 나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의
              도구-인터넷 옵션-개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부
              할 수 있습니다.
              <br />
              다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할
              수 있습니다.
            </div>
            <div>
              <p>제8조 (개인정보 보호책임자에 관한 사항)</p>
              <br /> ① 에스데넷 주식회사 은(는) 개인정보 처리에 관한 업무를
              총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및
              피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고
              있습니다. <br />▶ 개인정보 보호책임자 <br />
              성명 :전훈택 <br />
              직책 :대표이사 <br />
              직급 :대표이사 <br />
              연락처 :070-4220-8898, info@sdenet.net, 0507-087-8898 <br />※
              개인정보 보호 담당부서로 연결됩니다. <br />
              ▶ 개인정보 보호 담당부서 <br />
              부서명 :디지털융합팀 <br />
              담당자 :전훈택 <br />
              연락처 :070-0420-8898, info@sdenet.net, 0507-087-8898
              <br /> ② 정보주체께서는 에스데넷 주식회사 의 서비스(또는 사업)을
              이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리,
              피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로
              문의하실 수 있습니다. 에스데넷 주식회사 은(는) 정보주체의 문의에
              대해 지체 없이 답변 및 처리해드릴 것입니다.
            </div>
            <div>
              <p>제9조(개인정보의 열람청구를 접수·처리하는 부서)</p>
              <br /> 정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람
              청구를 아래의 부서에 할 수 있습니다. <br />
              에스데넷 주식회사은(는) 정보주체의 개인정보 열람청구가 신속하게
              처리되도록 노력하겠습니다. <br />
              ▶ 개인정보 열람청구 접수·처리 부서
              <br />
              부서명 : 디지털융합팀 <br />
              담당자 : 전훈택 <br />
              연락처 : 070-4220-8898, info@sdenet.net, 0507-087-8898
            </div>
            <div>
              <p>제10조(정보주체의 권익침해에 대한 구제방법)</p>
              <br />
              정보주체는 개인정보침해로 인한 구제를 받기 위하여
              개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
              분쟁해결이나 상담 등을 신청할 수 있습니다. <br />이 밖에 기타
              개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
              바랍니다. <br />
              1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972
              (www.kopico.go.kr) <br />
              2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
              <br /> 3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr) <br />
              4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
              「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의
              정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대
              하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
              이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
              청구할 수 있습니다. <br />※ 행정심판에 대해 자세한 사항은
              중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기
              바랍니다.
            </div>
            <div>
              <p>제11조(개인정보 처리방침 변경)</p> <br />① 이
              개인정보처리방침은 2023년 1월 1부터 적용됩니다. <br />② 이전의
              개인정보 처리방침은 아래에서 확인하실 수 있습니다.
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default PrivacyPolicy;
