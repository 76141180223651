import React from 'react';

const ITDevOverviewEng = () => {
  return (
    <>
      <div className='itdev__content__overviewvideo'>
        <video
          id='itdev-video'
          poster='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/videos/businessoverbanner(eng).mp4'
          preload='auto'
          autoPlay
          muted>
          <source
            src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/videos/businessoverbanner(eng).mp4'
            type='video/mp4'
          />
        </video>
      </div>
      <div className='itdev__content__description'>
        <div className='description-odd'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/save.png'
              alt='odd1'
            />
          </div>
          <div>
            <div className='divtitle-odd'>Cut down the money</div>
            <div className='divcontent-odd'>
              Reducing maintenance and management costs by reducing time to
              resolve problems within the enterprise,
              <br />
              Improvement of profit generation by creating an environment where
              tools and systems can be utilized to the fullest
            </div>
          </div>
        </div>
        <div className='description-even'>
          <div>
            <div className='divtitle-even'>
              Technological innovation through IT adaptability/speed improvement
            </div>
            <div className='divcontent-even'>
              Improve adaptability to the rapidly changing IT technology
              development speed Innovation runs smoothly,
              <br />
              laying the groundwork for the next level.
            </div>
          </div>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/idea.png'
              alt='odd1'
            />
          </div>
        </div>
        <div className='description-odd'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/privacy.png'
              alt='odd1'
            />
          </div>
          <div>
            <div className='divtitle-odd'>Strengthen IT security</div>
            <div className='divcontent-odd'>
              In response to ever-evolving IT security threats, we strengthen
              our security posture by utilizing reliable IT resources.
              <br />
              You can respond flexibly even when a new threat arises.
            </div>
          </div>
        </div>
        <div className='description-even'>
          <div>
            <div className='divtitle-even'>
              Corporate Efficiency Maximize Productivity
            </div>
            <div className='divcontent-even'>
              Because it supports and supplements the work of members through
              appropriate tools,
              <br />
              It can reduce business expenses and improve performance. <br />S
              DENET utilizes the latest technology to reduce the workload of
              members and improve productivity.
            </div>
          </div>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/analytics.png'
              alt='odd1'
            />
          </div>
        </div>
      </div>
      {/* <div className='itdev__content__techstack'>
        <p>기술 스택</p>
      </div> */}
    </>
  );
};

export default ITDevOverviewEng;
