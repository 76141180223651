import React, { useState } from 'react';
import '../../scss/components/ContentMain.scss';
import ITDevSolutionEng from './ITDevSolutionEng';
import ITDevOverviewEng from './ITDevOverviewEng';
import ITDevBigdataEng from './ITDevBigdataEng';
import ITDevIttechEng from './ITDevIttechEng';

const ITDevEng = () => {
  const [selected, setSelected] = useState(0);
  return (
    <section className='itdev'>
      <div className='itdev__tabs'>
        <span
          className={selected === 0 && 'selected'}
          onClick={() => setSelected(0)}>
          Outline
        </span>
        <span
          className={selected === 1 && 'selected'}
          onClick={() => setSelected(1)}>
          IT Solution Development
        </span>
        <span
          className={selected === 2 && 'selected'}
          onClick={() => setSelected(2)}>
          Big Data Analysis Service
        </span>
        <span
          className={selected === 3 && 'selected'}
          onClick={() => setSelected(3)}>
          IT Technical Support
        </span>
      </div>
      <div className='itdev__content'>
        {selected === 0 ? (
          <ITDevOverviewEng />
        ) : selected === 1 ? (
          <ITDevSolutionEng />
        ) : selected === 2 ? (
          <ITDevBigdataEng />
        ) : (
          <ITDevIttechEng />
        )}
      </div>
    </section>
  );
};

export default ITDevEng;
