import React from 'react';
import '../../scss/page/Business.scss';

const BidAgencyEng = () => {
  return (
    <div className='bidAgency'>
      <div className='textBox'>
        <h5>Bid prediction through big data analysis</h5>
        <span>
          Bidding analysis by applying big data analysis techniques to determine
          the final bid desired by the customer We will help you become a
          successful bidder.
        </span>
      </div>
      <div className='contents'>
        <div className='imgBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/BidAgency(eng).jpg' />
        </div>
      </div>
      <div className='contents'>
        <div className='imgBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/consulting+precess(eng).jpg' />
        </div>
        <div className='bidAgencyImgBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/Core+Competence+(eng).jpg' />
        </div>
      </div>
      <div className='contents'>
        <div className='imgBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/order+(eng).jpg' />
        </div>
      </div>
    </div>
  );
};

export default BidAgencyEng;
