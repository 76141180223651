import React from 'react';

const ITDevBigdataEng = () => {
  return (
    <>
      <div className='itdev__content__imgbigdata'>
        <img
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/bigdata.jpg'
          alt='bigdataphoto'
        />
        <div>
          <h2>
            Big data as the core technology of the 4th industrial revolution
          </h2>
          <h2>It is an important business asset.</h2>
          <br />
          <br />
          <p>For big data to become a strategic asset</p>
          <p>Apply big data analysis solution</p>
          <p>It needs to be reconfigured for a real business environment.</p>
        </div>
      </div>
      <div className='itdev__content__description'>
        <h4>Reasons to choose a big data solution</h4>
        <div className='itdev__content__description__reason'>
          <div className='description-block'>
            <div>Data Driven Decision Making</div>
            <div>
              Big data analysis enables accurate and rapid decision making.
            </div>
          </div>
          <div className='description-block'>
            <div>Prediction and Recommendation</div>
            <div>
              Leverage data models to provide future predictions and
              personalized recommendations.
            </div>
          </div>
          <div className='description-block'>
            <div>Improve Business Efficiency</div>
            <div>
              Data can be analyzed to optimize business processes and improve
              efficiency.
            </div>
          </div>
          <div className='description-block'>
            <div>Reinforcing Competitiveness </div>
            <div>
              Leverage data-driven insights to identify market trends and
              strengthen your competitive edge.
            </div>
          </div>
        </div>
      </div>
      <div className='itdev__content__descriptionimg'>
        <img
          className='imgmarginauto'
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/infograpbigdata(eng).jpg'
          alt='infobigdata'
        />
      </div>
    </>
  );
};

export default ITDevBigdataEng;
