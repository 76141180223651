import React from 'react';

const ITDevIttechEng = () => {
  return (
    <>
      <div className='itdev__content__imgcontainer'>
        <img
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/iot2.png'
          alt='iotphoto'
        />
      </div>
      <div className='itdev__content__description'>
        <h4 className='description__title'>
          We support comprehensive design related to IOT and field-oriented IT
          technology.
        </h4>
        <div className='description-left'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/cube.png'
              alt='odd1'
            />
          </div>
          <div>
            <div className='divtitle-odd'>Data Module</div>
            <div className='divcontent-odd'>
              Development of small but sophisticated and powerful data
              generation module
            </div>
          </div>
        </div>
        <div className='description-left'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/tap.png'
              alt='odd2'
            />
          </div>
          <div>
            <div className='divtitle-odd'>Event-Driven Architecture</div>
            <div className='divcontent-odd'>
              Detect motions (events) or business moments in objects and
              real-time / Design patterns that can operate in near real-time
            </div>
          </div>
        </div>
        <div className='description-left'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/shield.png'
              alt='odd3'
            />
          </div>
          <div>
            <div className='divtitle-odd'>Enhanced Security</div>
            <div className='divcontent-odd'>
              Leverage trusted resources to continuously strengthen your
              security posture.
            </div>
          </div>
        </div>
        <div className='description-left'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/handshake.png'
              alt='odd4'
            />
          </div>
          <div>
            <div className='divtitle-odd'>Active IT Technical Support</div>
            <div className='divcontent-odd'>
              Active and proactive support for improving IT adaptability of
              members of partner companies
            </div>
          </div>
        </div>
        <div className='description-left'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/clouddata.png'
              alt='odd5'
            />
          </div>
          <div>
            <div className='divtitle-odd'>Cloud-Based Service</div>
            <div className='divcontent-odd'>
              Easily scale as your business grows with cloud-based IT support
              services provide.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ITDevIttechEng;
