import React from 'react';
import '../scss/page/Home.scss';
import { BiCheckShield } from 'react-icons/bi';
import { RiServiceLine } from 'react-icons/ri';
import { MdOutlineSettingsSuggest } from 'react-icons/md';
import { TbArrowDownCircle } from 'react-icons/tb';

const Home = () => {
  return (
    <>
      <div className='home-content'>
        {/* 메인 첫 화면 */}
        <div
          className='swiper-slide-bg'
          style={{ backgroundColor: 'rgb(1, 13, 53)' }}>
          <video
            id='slide-video'
            poster='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/videos/Digitalization.mp4'
            preload='auto'
            loop
            autoPlay
            muted
            controls>
            <source
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/videos/Digitalization.mp4'
              type='video/mp4'
            />
          </video>
        </div>
        <div className='swiper-slide-text'>
          <h3>Welcome to SDENET!</h3>
          <span>
            에스데넷에서는
            <br />
            다양한 비즈니스 분야에서 신뢰할 수 있는 파트너로서
            <br />
            귀사의 요구사항을 충족하는 혁신적인 솔루션과 전문 서비스를
            제공합니다.
          </span>
        </div>
      </div>
      {/* 회사소개 */}
      <div className='companyContent'>
        <div className='container'>
          <h2>회사소개</h2>
          <div className='companyContent__mainTextBox'>
            <span>
              에스데넷은 고객에게 우수성, 혁신 및 가치를 제공하기 위해 최선을
              다하고 있습니다. 고객 중심의 접근 방식을 통해 다양한 산업 분야에서
              성공을 주도하기 위해 노력합니다. 귀하의 비즈니스를 새로운 차원으로
              끌어올릴 수 있는 경험을 해보십시오.
            </span>
            <div>
              <img
                src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/about-us.jpg'
                alt='about-us'
              />
            </div>
          </div>
          <div className='companyContent__subTextBox'>
            <div>
              <div className='companyContent__subTextBox__headerItem'>
                <RiServiceLine size={60} />
                <span>customer-oriented service</span>
                <h5>고객위주 서비스</h5>
              </div>
              <span className='companyContent__subTextBox__subItem'>
                개발 프로세스의 모든 단계에서 귀하와 적극적으로 협력함으로써
                강력한 파트너십을 육성할 수 있다고 믿습니다. 초기 브레인스토밍
                세션에서 최종 구현에 이르기까지 귀하의 비전이 우리가 만든
                솔루션에 원활하게 통합되도록 귀하와 긴밀히 협력합니다.
              </span>
            </div>
            <div>
              <div className='companyContent__subTextBox__headerItem'>
                <BiCheckShield size={60} />
                <span>professional system</span>
                <h5>전문적인 체계</h5>
              </div>
              <span className='companyContent__subTextBox__subItem'>
                업계 동향과 발전에 뒤떨어지지 않고 미래지향적이지만 신뢰할 수
                있는 솔루션을 제공할 수 있습니다. 혁신과 신뢰성 사이의 이러한
                균형은 우리의 전문적인 접근 방식을 정의합니다.
              </span>
            </div>
            <div>
              <div className='companyContent__subTextBox__headerItem'>
                <MdOutlineSettingsSuggest size={60} />
                <span>ship supplies supply</span>
                <h5>통합 기술 솔루션</h5>
              </div>
              <span className='companyContent__subTextBox__subItem'>
                자동화 및 효율화, 보안, 사용자 경험 등을 바탕으로
                <br />
                지속적인 개선을 통해 사용하기 쉬운 인터페이스를 제공합니다.
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* 사업분야 */}
      {/* <div className='businessContent'>
        <div className='container'>
          <h2>사업분야</h2>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/kor-item.jpg'
              alt='image'
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Home;
