import React from 'react';
import '../../scss/page/Business.scss';

const OilAnalysis = () => {
  return (
    <div className='oilAnalysis'>
      <div className='textBox'>
        <span>
          해양 유지 보수 및 운영 우수성의 핵심 구성 요소입니다. 선박 엔진 및
          기계에서 추출한 오일 샘플을 분석하여 선주, 운영자 및 엔지니어에게 실행
          가능한 통찰력을 제공하여 선박 성능을 향상하고 장비 수명을 연장하며
          유지 관리 관행을 최적화합니다.
        </span>
      </div>
      <div className='videoBox'>
        <video
          id='contents-video'
          poster='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/OilAnalysis.mp4'
          preload='auto'
          loop
          autoPlay
          muted>
          <source
            src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/OilAnalysis.mp4'
            type='video/mp4'
          />
        </video>
      </div>
      <div className='stepBox'>
        <ul>
          <li>
            <div className='stepBox__head'>1. 예방적 유지보수</div>
            <div className='stepBox__body'>
              오일 분석 서비스를 통해 잠재적인 장비 문제를 사전에 해결할 수
              있습니다. 윤활유 품질, 마모 금속 및 오염 수준을 모니터링하여
              이상을 조기에 감지하고 비용이 많이 드는 고장으로 확대되기 전에
              시정 조치를 취할 수 있도록 도와드립니다.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>2. 운영 효율성</div>
            <div className='stepBox__body'>
              깨끗하고 잘 관리된 오일은 효율적인 선박 운영에 필수적입니다.
              정밀한 오일 분석을 통해 최적의 윤활을 보장하고 마찰을 줄이며 최대
              엔진 효율을 달성하여 연료 절감 및 배출 감소로 이어질 수 있습니다.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>3. 연장된 장비 수명</div>
            <div className='stepBox__body'>
              정기적인 오일 분석은 마모 패턴 및 열화를 식별하는 데 도움이 되며
              선박 구성 요소의 작동 수명을 연장하는 시기적절한 개입을 가능하게
              합니다. 이 접근 방식은 조기 교체의 필요성을 최소화하고 지속 가능한
              자산 관리를 촉진합니다.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>4. 예산 최적화</div>
            <div className='stepBox__body'>
              오일 분석을 통한 예측 유지보수로 비용 효율적인 자산 관리가
              가능합니다. 예정되지 않은 다운타임을 피하고 문제를 사전에
              해결함으로써 유지 관리 예산을 최적화하고 리소스를 보다 효율적으로
              할당할 수 있습니다.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>5. 규정 준수 및 지속 가능성</div>
            <div className='stepBox__body'>
              오일 품질 모니터링은 환경 규정 및 지속 가능한 관행에 부합합니다.
              잘 관리된 기계는 석유 소비 감소, 배출 감소 및 환경적으로 책임 있는
              해상 작업에 기여합니다.
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OilAnalysis;
