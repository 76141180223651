import React from 'react';
import '../../scss/page/Business.scss';

const OilAnalysisEng = () => {
  return (
    <div className='oilAnalysis'>
      <div className='textBox'>
        <span>
          It is a key component of marine maintenance and operational
          excellence. marine engines and Oil samples extracted from the machine
          are analyzed and run by ship owners, operators and engineers Provides
          possible insights to improve vessel performance, extend equipment
          life, and Optimize your maintenance practices.
        </span>
      </div>
      <div className='videoBox'>
        <video
          id='contents-video'
          poster='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/OilAnalysis.mp4'
          preload='auto'
          loop
          autoPlay
          muted>
          <source
            src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/OilAnalysis.mp4'
            type='video/mp4'
          />
        </video>
      </div>
      <div className='stepBox'>
        <ul>
          <li>
            <div className='stepBox__head'>1. Preventive Maintenance</div>
            <div className='stepBox__body'>
              Oil analysis services can help you proactively address potential
              equipment problems. By monitoring lubricant quality, wear metals
              and contamination levels, we can help you detect anomalies early
              and take corrective action before they escalate into costly
              failures.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>2. Operational Efficiency</div>
            <div className='stepBox__body'>
              Clean, well-maintained oil is essential for efficient ship
              operation. Precise oil analysis ensures optimal lubrication,
              reduces friction and achieves maximum engine efficiency, which can
              lead to fuel savings and reduced emissions.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>3. Extended Equipment Life</div>
            <div className='stepBox__body'>
              Regular oil analysis helps identify wear patterns and
              deterioration and allows for timely interventions that prolong the
              operating life of ship components. This approach minimizes the
              need for premature replacement and promotes sustainable asset
              management.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>4. Budget Optimization</div>
            <div className='stepBox__body'>
              Predictive maintenance with oil analysis enables cost-effective
              asset management. Optimize your maintenance budget and allocate
              resources more efficiently by avoiding unscheduled downtime and
              proactively addressing issues.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>
              5. Compliance and Sustainability
            </div>
            <div className='stepBox__body'>
              Oil quality monitoring is in line with environmental regulations
              and sustainable practices. Well-maintained machinery contributes
              to reduced oil consumption, reduced emissions and environmentally
              responsible offshore operations.
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OilAnalysisEng;
