import React from 'react';

const ITDevIttech = () => {
  return (
    <>
      <div className='itdev__content__imgcontainer'>
        <img
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/iot2.png'
          alt='iotphoto'
        />
      </div>
      <div className='itdev__content__description'>
        <h4 className='description__title'>
          IOT와 관련된 종합적인 설계와 현장 중심의 IT 기술을 지원합니다.
        </h4>
        <div className='description-left'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/cube.png'
              alt='odd1'
            />
          </div>
          <div>
            <div className='divtitle-odd'>데이터 모듈</div>
            <div className='divcontent-odd'>
              작지만 정교하고 강력한 데이터 생성 모듈 개발
            </div>
          </div>
        </div>
        <div className='description-left'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/tap.png'
              alt='odd2'
            />
          </div>
          <div>
            <div className='divtitle-odd'>이벤트 중심의 아키텍처</div>
            <div className='divcontent-odd'>
              사물에 동작(이벤트) 혹은 비즈니스 순간을 감지하고 실시간 /
              준실시간으로 동작 할 수 있는 설계 패턴
            </div>
          </div>
        </div>
        <div className='description-left'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/shield.png'
              alt='odd3'
            />
          </div>
          <div>
            <div className='divtitle-odd'>보안 강화</div>
            <div className='divcontent-odd'>
              신뢰할 수 있는 자원을 활용하여 지속적으로 보안 태세를 강화합니다.
            </div>
          </div>
        </div>
        <div className='description-left'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/handshake.png'
              alt='odd4'
            />
          </div>
          <div>
            <div className='divtitle-odd'>적극적인 IT 기술지원</div>
            <div className='divcontent-odd'>
              파트너사 구성원의 IT 적응력 향상을 위한 능동적이고 적극적인 지원
            </div>
          </div>
        </div>
        <div className='description-left'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/clouddata.png'
              alt='odd5'
            />
          </div>
          <div>
            <div className='divtitle-odd'>클라우드 기반 서비스</div>
            <div className='divcontent-odd'>
              클라우드 기반 IT 지원 서비스로 비즈니스 성장에 맞는 손쉬운 확장을
              제공합니다.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ITDevIttech;
