import React from 'react';
import ContentTitle from '../components/ContentTitle';
import '../scss/page/Recruit.scss';
import {
  SiAmazonaws,
  SiAmazonec2,
  SiGitlab,
  SiJavascript,
  SiMongodb,
  SiNotion,
  SiPostgresql,
  SiReact,
  SiSwagger,
  SiTypescript,
  SiNextdotjs,
  SiTailwindcss,
  SiNestjs,
} from 'react-icons/si';
import { LiaNode } from 'react-icons/lia';

const RecruitEng = () => {
  return (
    <div className='recruit pagemargin'>
      <ContentTitle title='Recruit' />
      <div className='content__body container'>
        <div className='recruit__textBox'>
          <h5>Ideal Talent</h5>
        </div>
        <div className='recruit__imgBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/ideal-talent+(eng).jpg' />
        </div>
        <div className='recruit__textBox'>
          <h5>Technology Stack</h5>
        </div>

        <div className='recruit__stackBox'>
          <div className='recruit__stackBox__itemBox'>
            <p>Programming Language</p>
            <div>
              <div>
                <SiJavascript size={40} fill='#fad014' />
                <span>JavaScript</span>
              </div>

              <div>
                <SiTypescript size={40} fill='#2F74C0' />
                <span>TypeScript</span>
              </div>
            </div>
          </div>

          <div className='recruit__stackBox__itemBox'>
            <p>Front-end</p>
            <div>
              <div>
                <SiReact size={45} fill='#00BFFF' />
                <span>React.js</span>
              </div>

              <div>
                <SiNextdotjs size={45} fill='#000000' />
                <span>Next.js</span>
              </div>

              <div>
                <SiTailwindcss size={45} fill='#36B7F0' />
                <span>TailwindCSS</span>
              </div>

              <div>
                <img
                  src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/tanstackquery.png'
                  alt='tanstackquery'
                  style={{ width: '45px', marginRight: '5px' }}
                />
                <span>Tanstack Query</span>
              </div>

              <div>
                <span>
                  <img
                    src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/zustand.png'
                    alt='zustand'
                    style={{ width: '45px', marginRight: '5px' }}
                  />
                  Zustand
                </span>
              </div>
            </div>
          </div>

          <div className='recruit__stackBox__itemBox'>
            <p>Back-end</p>
            <div>
              <div>
                <LiaNode size={50} fill='#27c427' />
                <span>Node.js</span>
              </div>

              <div>
                <SiNestjs size={50} fill='#D9224C' />
                <span>Nest.js</span>
              </div>

              <div>
                <SiSwagger size={45} fill='	#6B8E23' />
                <span>Swagger</span>
              </div>
            </div>
          </div>

          <div className='recruit__stackBox__itemBox'>
            <p>DataBase</p>
            <div>
              <div>
                <SiMongodb size={45} fill='#008000' />
                <span>MongoDB</span>
              </div>

              <div>
                <img
                  src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/mysql.png'
                  alt='mysql'
                  style={{ width: '50px', marginRight: '5px' }}
                />
                <span>MySQL</span>
              </div>

              <div>
                <SiPostgresql size={45} fill='#4682B4' />
                <span>PostgreSQL</span>
              </div>
            </div>
          </div>

          <div className='recruit__stackBox__itemBox'>
            <p>DevOps</p>
            <div>
              <div>
                <span>
                  <SiAmazonaws size={45} fill='#FFA500' />
                  AWS
                </span>
              </div>

              <div>
                <img
                  src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/awsAmplify.png'
                  alt='AWS Amplify'
                  style={{ width: '50px', marginRight: '5px' }}
                />
                <span>AWS Amplify</span>
              </div>

              <div>
                <SiAmazonec2 size={40} fill='#FF8C00' />
                <span>Amazon EC2</span>
              </div>

              <div>
                <SiGitlab size={40} fill='#ff652e' />
                <span>GitLab</span>
              </div>
            </div>
          </div>

          <div className='recruit__stackBox__itemBox'>
            <p>Collaboration Tool</p>
            <div>
              <div>
                <SiNotion size={40} />
                <span>Notion</span>
              </div>

              <div>
                <img
                  src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/gather.png'
                  alt='gather'
                  style={{ width: '45px', marginRight: '5px' }}
                />
                <span>Gather</span>
              </div>

              <div>
                <img
                  src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/slack.png'
                  alt='slack'
                  style={{ width: '45px', marginRight: '5px' }}
                />
                <span>Slack</span>
              </div>
            </div>
          </div>
        </div>

        <div className='recruit__textBox'>
          <h5>Recruitment process</h5>
          <span>
            *If false information is found in the job application documents, the
            hiring process will be denied even after the hiring is confirmed.
            may be canceled.
          </span>
          <span>
            *Recruitment procedures may change depending on company
            circumstances.
          </span>
        </div>
        <div className='recruit__stepBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/Recruitment+procedure(eng).jpg' />
        </div>
      </div>
    </div>
  );
};

export default RecruitEng;
