import React from 'react';

const ITDevSolutionEng = () => {
  return (
    <>
      <div className='itdev__content__imgsolution'>
        <h4>SI Business</h4>
        <img
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/SI2(eng).jpg'
          alt='sol2'
        />
        <img
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/SI1(eng).jpg'
          alt='sol1'
        />
        <img
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/SI3(eng).jpg'
          alt='sol3'
        />
      </div>
      <div className='itdev__content__description'></div>
    </>
  );
};

export default ITDevSolutionEng;
