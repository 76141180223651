import React from 'react';

const DroneDev = () => {
  return (
    <div className='droneDev'>
      <div className='textBox'>
        <span>
          드론산업은 혁신성장의 8대 핵심 선도사업으로,
          항공·정보통신(ICT)·소프트웨어(SW)·센서 등 첨단기술의 융합산업입니다.
          <br />
          현재 드론 산업은 저가·소형 중심의 단순 촬영용에서 농업·감시·측량·배송
          등 임무 수행을 위한 고가·중형 중심으로 점점 규모가 커지고 있으며
          <br />
          전세계적으로 시장 선점을 위해 경쟁하고 있습니다.
        </span>
        <span>
          1. 물류배송 <br />- 도서ㆍ산간 지역의 신속/안전한 물류 배송
          <br />- 긴급 물품의 신속배송
        </span>
        <div className=''>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/drone2.jpg' />
        </div>
        <br />
        <span>
          2. 영상촬영 <br />- 안정적인 항공촬영으로 정확한 영상정보 수집
          <br />- 다양한 각도에서의 촬영 - 촬영 장소 / 환경의 극복
        </span>
        <div className=''>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/drone1.jpg' />
        </div>
      </div>
    </div>
  );
};

export default DroneDev;
