import React from 'react';
import { TbArrowDownCircle } from 'react-icons/tb';
import { MdOutlineSettingsSuggest } from 'react-icons/md';
import { BiCheckShield } from 'react-icons/bi';
import { RiServiceLine } from 'react-icons/ri';

const HomeEng = () => {
  return (
    <>
      <div className='home-content'>
        {/* 메인 첫 화면 */}
        <div
          className='swiper-slide-bg'
          style={{ backgroundColor: 'rgb(1, 13, 53)' }}>
          <video
            id='slide-video'
            poster='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/videos/Digitalization.mp4'
            preload='auto'
            loop
            autoPlay
            muted>
            <source
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/videos/Digitalization.mp4'
              type='video/mp4'
            />
          </video>
        </div>
        <div className='swiper-slide-text'>
          <h3>Welcome to S DENET!</h3>
          <span>
            S DENET is
            <br />
            As a reliable business partner,
            <br />
            provide innovative solutions and professional services.
          </span>
        </div>
      </div>
      {/* 회사소개 */}
      <div className='companyContent'>
        <div className='container'>
          <h2 className='engh2'>About S DENET</h2>
          <div className='companyContent__mainTextBox'>
            <span>
              S DENET is committed to providing interpretability, interpretation
              and value to our customers. A variety of industries through
              customer approach Strive to remain successful in the field. Get an
              experience that will take your business to a new level.
            </span>
            <div>
              <img
                src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/about-us.jpg'
                alt='about-us'
              />
            </div>
          </div>
          <div className='companyContent__subTextBox'>
            <div>
              <div className='companyContent__subTextBox__headerItem'>
                <RiServiceLine size={60} />
                <span>고객위주의 서비스</span>
                <h5>Customer-Oriented Service</h5>
              </div>
              <span className='companyContent__subTextBox__subItem'>
                From the initial brainstorming session through to final
                implementation, we work closely with you to bring your vision
                into the solution we've created, incorporating you.
              </span>
            </div>
            <div>
              <div className='companyContent__subTextBox__headerItem'>
                <BiCheckShield size={60} />
                <span>전문적인 체계</span>
                <h5>Professional System</h5>
              </div>
              <span className='companyContent__subTextBox__subItem'>
                We can provide future-oriented yet reliable solutions that keep
                up with industry trends and developments. This balance between
                innovation and reliability defines our professional approach.
              </span>
            </div>
            <div>
              <div className='companyContent__subTextBox__headerItem'>
                <MdOutlineSettingsSuggest size={60} />
                <span>통합 기술 솔루션</span>
                <h5>Ship Supplies Supply</h5>
              </div>
              <span className='companyContent__subTextBox__subItem'>
                It provides an easy-to-use interface through continuous
                improvement based on automation and efficiency, security, and
                user experience.
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* 사업분야 */}
      {/* <div className='businessContent'>
        <div className='container'>
          <h2 className='engh2'>BUSINESS AREAS</h2>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/eng-item.jpg'
              alt='image'
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default HomeEng;
