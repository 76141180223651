import React from 'react';
import '../scss/layout/Footer.scss';
import { Link, useLocation } from 'react-router-dom';
import { BsEnvelope, BsHeadphones } from 'react-icons/bs';

const Footer = () => {
  const path = useLocation();

  return (
    <footer id='footer'>
      <div className='footer__links'>
        <div className='container'>
          {path.pathname.includes('/eng') ? (
            <>
              <Link to='/eng/privacypolicy'>Privacy Policy</Link>
              <span>|</span>
              <Link to='/eng/refuseemail'>Refuse Collect E-mail</Link>
            </>
          ) : (
            <>
              <Link to='/privacypolicy'>개인정보처리방침</Link>
              <span>|</span>
              <Link to='/refuseemail'>이메일무단수집거부</Link>
            </>
          )}
        </div>
      </div>

      <div id='copyrights'>
        <div className='container'>
          <div className='row col-mb-30'>
            <div className='col-md-2 text-center text-md-start'>
              <img
                style={{ height: '5vh' }}
                src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/%C3%AC%C2%97%C2%90%C3%AC%C2%8A%C2%A4%C3%AB%C2%8D%C2%B0%C3%AB%C2%84%C2%B7-%C3%AC%C2%B5%C2%9C%C3%AC%C2%A2%C2%85_333_4_-removebg-preview-1691562997870.png'
                alt='footer-log'
                className='footer-logo'
              />
            </div>

            <div className='copyrights'>
              Copyrights &copy; 2024 All Rights Reserved by S DENET Co.,Ltd
            </div>

            <div className='contactinfo '>
              <BsEnvelope size={14} /> <span>Mail - </span>
              <span>info@sdenet.net</span>
              <span className='middot'>|</span>
              <BsHeadphones size={14} /> <span>Tel - </span>
              <span>070-4220-8898</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
