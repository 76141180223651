import React from 'react';

const ITDevSolution = () => {
  return (
    <>
      <div className='itdev__content__imgsolution'>
        <h4>SI 사업</h4>
        <img
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/SI2.jpg'
          alt='sol2'
        />
        <img
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/SI1.jpg'
          alt='sol1'
        />
        <img
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/SI3.jpg'
          alt='sol3'
        />
      </div>
      <div className='itdev__content__description'></div>
    </>
  );
};

export default ITDevSolution;
