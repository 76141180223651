import React from 'react';

const ResearchSer = () => {
  return (
    <div className='research'>
      <div className='textBox'>
        <h5>국책과제와 지자체에서 시행하는 연구용역을 수행합니다.</h5>
        <span>
          해당 연구 과제와 관련된 전문인력을 동원하여 주어진 과제를
          신속/정확하게 수행합니다.
        </span>
      </div>
      <div className='imgBox'>
        <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/research.jpg' />
      </div>
    </div>
  );
};

export default ResearchSer;
