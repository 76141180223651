import React, { useState } from 'react';
import ContentTitle from '../components/ContentTitle';
import ContentTabs from '../components/ContentTabs';
import ITDev from './business/ITDev';
import DroneDev from './business/DroneDev';
import BidAgency from './business/BidAgency';
import SuppliyStore from './business/SuppliyStore';
import ResearchSer from './business/ResearchSer';
import ConsortiumEd from './business/ConsortiumEd';
import OilAnalysis from './business/OilAnalysis';

const Business = () => {
  const tabdata = [
    { id: 0, label: 'IT 개발' },
    // { id: 1, label: '드론 상용화기술' },
    { id: 2, label: '입찰대행' },
    // { id: 3, label: '선용품 공급' },
    // { id: 4, label: '연구용역' },
    { id: 5, label: '컨소시엄 교육' },
    // { id: 6, label: '유류 분석서비스' },
  ];
  const [active, setActive] = useState(tabdata[0]);

  return (
    <div className='business pagemargin'>
      {/* 상부 사업분야 탭 */}
      <ContentTabs tabdata={tabdata} active={active} setActive={setActive} />

      <ContentTitle title={active.label} />
      <div className='content__body container'>
        {active.id === 0 ? (
          <ITDev />
        ) : active.id === 1 ? (
          <DroneDev />
        ) : active.id === 2 ? (
          <BidAgency />
        ) : active.id === 3 ? (
          <SuppliyStore />
        ) : active.id === 4 ? (
          <ResearchSer />
        ) : active.id === 5 ? (
          <ConsortiumEd />
        ) : (
          <OilAnalysis />
        )}
      </div>
    </div>
  );
};

export default Business;
