import React from 'react';

const ITDevOverview = () => {
  return (
    <>
      <div className='itdev__content__overviewvideo'>
        <video
          id='itdev-video'
          poster='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/videos/businessoverbanner.mp4'
          preload='auto'
          autoPlay
          muted>
          <source
            src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/videos/businessoverbanner.mp4'
            type='video/mp4'
          />
        </video>
      </div>
      <div className='itdev__content__description'>
        <div className='description-odd'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/save.png'
              alt='odd1'
            />
          </div>
          <div>
            <div className='divtitle-odd'>비용절감</div>
            <div className='divcontent-odd'>
              기업내 문제 해결 시간 단축하여 유지보수 및 관리 비용 절감, <br />
              Tool과 시스템을 최대한 활용할 수 있는 환경 조성하여 통해 수익
              창출의 향상
            </div>
          </div>
        </div>
        <div className='description-even'>
          <div>
            <div className='divtitle-even'>
              IT 적응력/속도 향상을 통한 기술 혁신
            </div>
            <div className='divcontent-even'>
              빠르게 변하는 IT 기술의 발전 속도에 적응력을 향상시켜 <br />
              혁신이 원할하게 진행되어 다음 레벨로 향하는 기반을 확립할 수
              있습니다.
            </div>
          </div>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/idea.png'
              alt='odd1'
            />
          </div>
        </div>
        <div className='description-odd'>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/privacy.png'
              alt='odd1'
            />
          </div>
          <div>
            <div className='divtitle-odd'>IT 보안 강화</div>
            <div className='divcontent-odd'>
              더욱도 진화하는 IT 보안 위협에 대응하여 신뢰할 수 있는 IT 자원을
              활용하여 보안 태세를 강화하여 <br /> 새로운 위협이 발생했을 때도
              유연하게 대처가능합니다.
            </div>
          </div>
        </div>
        <div className='description-even'>
          <div>
            <div className='divtitle-even'>기업 효율성 생산성 극대화</div>
            <div className='divcontent-even'>
              적합한 TOOL을 통해서 구성원의 작업을 지원하고 보완하기 때문에
              기업의 지출을 절감할 수 있고 성과를 향상시킬 수 있습니다. <br />S
              DENET은 최신 기술을 활용하여 구성원의 업무 부담을 줄이고 생산성을
              향상시킵니다.
            </div>
          </div>
          <div>
            <img
              src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/analytics.png'
              alt='odd1'
            />
          </div>
        </div>
      </div>
      {/* <div className='itdev__content__techstack'>
        <p>기술 스택</p>
      </div> */}
    </>
  );
};

export default ITDevOverview;
