import React from 'react';

const ITDevBigdata = () => {
  return (
    <>
      <div className='itdev__content__imgbigdata'>
        <img
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/bigdata.jpg'
          alt='bigdataphoto'
        />
        <div>
          <h2>빅데이터, 4차산업혁명의 핵심기술로</h2>
          <h2>중요한 비즈니스 자산입니다.</h2>
          <br />
          <br />
          <p>빅데이터가 전략적 자산이 되기 위해선</p>
          <p>빅데이터 분석 솔루션을 적용해</p>
          <p>실제 기업 환경에 맞게 재구성되어야 합니다.</p>
        </div>
      </div>

      <div className='itdev__content__description'>
        <h4>빅데이터 솔루션을 선택해야하는 이유</h4>
        <div className='itdev__content__description__reason'>
          <div className='description-block'>
            <div>데이터 기반 의사결정</div>
            <div>
              빅데이터 분석을 통해 정확하고 신속한 의사결정을 할 수 있습니다.
            </div>
          </div>
          <div className='description-block'>
            <div>예측과 추천</div>
            <div>
              데이터 모델을 활용하여 미래 예측과 개인화된 추천을 제공할 수
              있습니다.
            </div>
          </div>
          <div className='description-block'>
            <div>비즈니스 효율성 향상</div>
            <div>
              데이터를 분석하여 비즈니스 프로세스를 최적화하고 효율성을 향상시킬
              수 있습니다.
            </div>
          </div>
          <div className='description-block'>
            <div>경쟁력 강화</div>
            <div>
              데이터에 기반한 인사이트를 활용하여 시장 동향을 파악하고 경쟁력을
              강화할 수 있습니다.
            </div>
          </div>
        </div>
      </div>
      <div className='itdev__content__descriptionimg'>
        <img
          className='imgmarginauto'
          src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/infograpbigdata.png'
          alt='infobigdata'
        />
      </div>
    </>
  );
};

export default ITDevBigdata;
