import React from 'react';
import '../scss/components/ContentTabs.scss';

const ContentTabs = ({ tabdata, active, setActive }) => {
  return (
    <section className='content__subheader'>
      <div className='content__tabs'>
        {tabdata.map(tab => (
          <span
            className={`tab ${tab.id === active.id && 'active'}`}
            onClick={() => setActive(tab)}>
            {tab.label}
          </span>
        ))}
      </div>
    </section>
  );
};

export default ContentTabs;
