import React from 'react';

const ResearchSerEng = () => {
  return (
    <div className='research'>
      <div className='textBox'>
        <h5>
          We carry out research services conducted by national projects and
          local governments.
        </h5>
        <span>
          Experts related to research tasks can be quickly and accurately
          carried out as a project. do.
        </span>
      </div>
      <div className='imgBox'>
        <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/research(eng).jpg' />
      </div>
    </div>
  );
};

export default ResearchSerEng;
