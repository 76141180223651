import React from 'react';
import '../../scss/page/Business.scss';

const ConsortiumEd = () => {
  return (
    <div className='consortium'>
      <div className='textBox'>
        <span>
          - 컨소시엄이란 공통의 목적을 위한 협회나 조합을 의미하며, 일반적으로
          정부나 공공기관이 추진하는 대규모 사업에 여러 개의 업체가 한 회사의
          형태로 참여하는 경우를 말합니다.
        </span>
        <span>
          - 해양 우수성에 전념하는 포괄적인 솔루션 회사로서 전문 지식을 교육
          영역으로 확장하는 데 큰 자부심을 가지고 있습니다.
          <br />
          &nbsp; &nbsp;개인과 조직이 해양 산업의 복잡한 해역을 탐색하는 데
          필수적인 지식과 기술을 갖추도록 세심하게 제작되었습니다.
        </span>
      </div>
      <div className='videoBox'>
        <video
          id='contents-video'
          poster='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/ConsortiumEd.mp4'
          preload='auto'
          loop
          autoPlay
          muted>
          <source
            src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/ConsortiumEd.mp4'
            type='video/mp4'
          />
        </video>
      </div>
      <div className='stepBox'>
        <ul>
          <li>
            <div className='stepBox__head'>1. 전체적인 커리큘럼</div>
            <div className='stepBox__body'>
              해양법, 선박 운영, 물류, 항만 관리, 해양 공학, 환경 지속 가능성 및
              기술 발전을 포함한 다양한 주제를 포괄합니다.
              <br />
              참가자는 업계에 대한 360도 관점을 얻고 정보에 입각한 결정을 내리고
              혁신을 주도할 수 있습니다.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>2. 산업 중심 통찰력</div>
            <div className='stepBox__body'>
              뿌리 깊은 산업 연결을 통해 비교할 수 없는 통찰력을 제공할 수
              있습니다. 업계 리더, 규제 기관 및 저명한 학자들과 협력하여 교육이
              최신의 관련성을 유지하고 새로운 트렌드에 부합하도록 보장합니다.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>3. 경험적 학습</div>
            <div className='stepBox__body'>
              실용적인 적용은 에스데넷 컨소시엄 교육 접근 방식의 핵심입니다.
              <br />
              참가자는 실습 시뮬레이션, 사례 연구 및 실제 시나리오에 참여하여
              이론과 실제 사이의 격차를 해소합니다.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>4. 글로벌 관점</div>
            <div className='stepBox__body'>
              해양 산업은 국경을 초월하며 교육도 마찬가지입니다.
              <br />
              참가자들이 국제 무대에서 효과적으로 운영할 수 있도록 준비하면서
              글로벌 전망을 제공하도록 설계되었습니다.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>5. 네트워킹 기회</div>
            <div className='stepBox__body'>
              당사의 교육 플랫폼은 전문가들이 연결하고 협력하며 지속적인 관계를
              구축할 수 있는 넥서스 역할을 합니다.
              <br />
              네트워킹 이벤트, 포럼 및 업계 컨퍼런스는 아이디어와 경험의 유익한
              교환을 위한 환경을 조성합니다.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>6. 맞춤형 솔루션</div>
            <div className='stepBox__body'>
              모든 개인과 조직에는 고유한 요구 사항이 있다는 점을 인식하여
              맞춤형 교육 솔루션을 제공합니다.
              <br />
              특정 팀을 위한 맞춤형 교육이든 개인을 위한 포괄적인 과정이든
              다양한 요구 사항에 맞게 제공 사항을 조정합니다.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>7. 지속 가능한 관행</div>
            <div className='stepBox__body'>
              책임 있는 해양 관행을 옹호하는 교육 프로그램은 환경 의식을
              강조하여 바다와 지구를 보호하는 지속 가능한 운영을 촉진합니다.
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ConsortiumEd;
