import React from 'react';
import '../../scss/page/Business.scss';

const BidAgency = () => {
  return (
    <div className='bidAgency'>
      <div className='textBox'>
        <h5>빅데이터 분석을 통한 입찰가 예측</h5>
        <span>
          빅데이터 분석 기법을 적용하여 입찰분석하여 고객이 원하는 입찰의 최종
          낙찰자가 될 수 있게 지원해 드립니다.
        </span>
      </div>
      <div className='contents'>
        <div className='imgBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/BidAgency.jpg' />
        </div>
      </div>
      <div className='contents'>
        <div className='imgBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/consulting+precess.jpg' />
        </div>
        <div className='bidAgencyImgBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/Core+Competence+.jpg' />
        </div>
      </div>
      <div className='contents'>
        <div className='imgBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/order.jpg' />
        </div>
      </div>
    </div>
  );
};

export default BidAgency;
