import React from 'react';

const DroneDevEng = () => {
  return (
    <div className='droneDev'>
      <div className='textBox'>
        <span>
          The drone industry is one of the 8 core leading businesses for
          innovative growth, It is a convergence industry of advanced
          technologies such as aviation, information communication (ICT),
          software (SW), and sensors.
          <br />
          Currently, the drone industry is focused on low-cost and small-sized
          drones for simple shooting, agriculture, monitoring, surveying, and
          delivery. The scale is gradually increasing, focusing on high-end and
          medium-sized vehicles for missions such as
          <br />
          We are competing for market dominance all over the world.
        </span>
        <span>
          1. Logistics delivery <br />- Fast/safe logistics delivery in islands
          and mountainous areas
          <br />- Expedited delivery of urgent items
        </span>
        <div className='imgBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/drone2.jpg' />
        </div>
        <br />
        <span>
          2. Video shooting <br />- Accurate video information collection
          through stable aerial photography
          <br />- Shooting from various angles - Overcoming the shooting
          location/environment
        </span>
        <div className='imgBox'>
          <img src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/drone1.jpg' />
        </div>
      </div>
    </div>
  );
};

export default DroneDevEng;
