import React, { useState } from 'react';
import '../../scss/components/ContentMain.scss';
import ITDevIttech from './ITDevIttech';
import ITDevBigdata from './ITDevBigdata';
import ITDevSolution from './ITDevSolution';
import ITDevOverview from './ITDevOverview';

const ITDev = () => {
  const [selected, setSelected] = useState(0);
  return (
    <section className='itdev'>
      <div className='itdev__tabs'>
        <span
          className={selected === 0 && 'selected'}
          onClick={() => setSelected(0)}>
          개요
        </span>
        <span
          className={selected === 1 && 'selected'}
          onClick={() => setSelected(1)}>
          IT 솔루션 개발
        </span>
        <span
          className={selected === 2 && 'selected'}
          onClick={() => setSelected(2)}>
          빅데이터 분석 서비스
        </span>
        <span
          className={selected === 3 && 'selected'}
          onClick={() => setSelected(3)}>
          IT 기술지원
        </span>
      </div>
      <div className='itdev__content'>
        {selected === 0 ? (
          <ITDevOverview />
        ) : selected === 1 ? (
          <ITDevSolution />
        ) : selected === 2 ? (
          <ITDevBigdata />
        ) : (
          <ITDevIttech />
        )}
      </div>
    </section>
  );
};

export default ITDev;
