import React from 'react';
import '../../scss/page/Business.scss';

const ConsortiumEdEng = () => {
  return (
    <div className='consortium'>
      <div className='textBox'>
        <span>
          - A consortium refers to an association or association for a common
          purpose, and generally refers to a case in which several companies
          participate in a large-scale project promoted by the government or
          public institution in the form of a single company.
        </span>
        <span>
          - As a comprehensive solutions company dedicated to maritime
          excellence, we take great pride in extending our expertise into the
          realm of education.
          <br />
          &nbsp; &nbsp;It is carefully crafted to equip individuals and
          organizations with the knowledge and skills essential to navigating
          the complex waters of the maritime industry.
        </span>
      </div>
      <div className='videoBox'>
        <video
          id='contents-video'
          poster='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/ConsortiumEd.mp4'
          preload='auto'
          loop
          autoPlay
          muted>
          <source
            src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/ConsortiumEd.mp4'
            type='video/mp4'
          />
        </video>
      </div>
      <div className='stepBox'>
        <ul>
          <li>
            <div className='stepBox__head'>1. Overall Curriculum</div>
            <div className='stepBox__body'>
              Maritime Law, Ship Operations, Logistics, Port Management, Marine
              Engineering, Environmental Sustainability and It covers a wide
              range of topics, including technological advances.
              <br />
              Participants will gain a 360-degree view of the industry, make
              informed decisions, and You can drive innovation.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>2. Industry-Focused Insights</div>
            <div className='stepBox__body'>
              Our deep-rooted industry connections can provide you with
              unparalleled insights. there is. Collaborating with industry
              leaders, regulators and renowned academics, education is It
              ensures that you stay up to date, relevant and in line with new
              trends.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>3. Experiential Learning</div>
            <div className='stepBox__body'>
              Practical application is at the heart of the Esdenet Consortium's
              educational approach.
              <br />
              Participants engage in hands-on simulations, case studies, and
              real-world scenarios to Bridges the gap between theory and
              practice.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>4. Global Perspective</div>
            <div className='stepBox__body'>
              The maritime industry crosses borders and so does education.
              <br />
              Preparing participants to operate effectively on the international
              stage Designed to provide a global outlook.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>5. Networking Opportunities</div>
            <div className='stepBox__body'>
              Our educational platform enables professionals to connect,
              collaborate and build lasting relationships. It serves as a nexus
              you can build on.
              <br />
              Networking events, forums and industry conferences are beneficial
              for ideas and experiences. Create an environment for exchange.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>6. Custom Solution</div>
            <div className='stepBox__body'>
              Recognizing that every individual and organization has unique
              needs, We provide customized training solutions.
              <br />
              Whether it's tailor-made training for a specific team or a
              comprehensive course for individuals. We tailor our offerings to
              suit different needs.
            </div>
          </li>
          <li>
            <div className='stepBox__head'>7. Sustainable Practices</div>
            <div className='stepBox__body'>
              Educational programs advocating responsible maritime practices
              promote environmental awareness. to promote sustainable operations
              that protect our oceans and planet.
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ConsortiumEdEng;
