/** @format */
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Layout from './layout/Layout';
import LayoutEng from './layout/LayoutEng';
import Home from './page/Home';
import HomeEng from './page/HomeEng';
import Company from './page/Company';
import CompanyEng from './page/CompanyEng';
import CustomerSupport from './page/CustomerSupport';
import Business from './page/Business';
import Recruit from './page/Recruit';
import Information from './page/Information';
import NotFound from './page/NotFound';
import PrivacyPolicy from './page/PrivacyPolicy';
import RefuseEmail from './page/RefuseEmail';
import BusinessEng from './page/BusinessEng';
import InformationEng from './page/InformationEng';
import RecruitEng from './page/RecruitEng';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='*' element={<NotFound />} />
        {/* <Route path='company' element={<Company />} /> */}
        <Route path='business' element={<Business />} />
        <Route path='recruit' element={<Recruit />} />
        {/* <Route path='information' element={<Information />} /> */}
        {/* <Route path='customerSupport' element={<CustomerSupport />} /> */}
        <Route path='privacypolicy' element={<PrivacyPolicy />} />
        <Route path='refuseemail' element={<RefuseEmail />} />
      </Route>

      <Route path='/eng' element={<LayoutEng />}>
        <Route index element={<HomeEng />} />
        {/* <Route path='company' element={<CompanyEng />} /> */}
        <Route path='business' element={<BusinessEng />} />
        <Route path='recruit' element={<RecruitEng />} />
        {/* <Route path='information' element={<InformationEng />} /> */}
        {/* <Route path='customerSupport' element={<CustomerSupport />} /> */}
        <Route path='privacypolicy' element={<PrivacyPolicy />} />
        <Route path='refuseemail' element={<RefuseEmail />} />
      </Route>
    </Routes>
  );
}

export default App;
