import React, { useState } from 'react';
import ContentTitle from '../components/ContentTitle';
import ContentTabs from '../components/ContentTabs';
import ITDevEng from './business/ITDevEng';
import DroneDevEng from './business/DroneDevEng';
import BidAgencyEng from './business/BidAgencyEng';
import SuppliyStoreEng from './business/SuppliyStoreEng';
import ResearchSerEng from './business/ResearchSerEng';
import ConsortiumEdEng from './business/ConsortiumEdEng';
import OilAnalysisEng from './business/OilAnalysisEng';

const BusinessEng = () => {
  const tabdata = [
    { id: 0, label: 'IT Development' },
    // { id: 1, label: 'Drone Development' },
    { id: 2, label: 'Bidding Agency' },
    // { id: 3, label: 'Suppliy Ship Stores' },
    // { id: 4, label: 'Research Services' },
    { id: 5, label: 'Consortium Education' },
    // { id: 6, label: 'Oil Analysis' },
  ];
  const [active, setActive] = useState(tabdata[0]);

  return (
    <div className='business pagemargin'>
      <ContentTabs tabdata={tabdata} active={active} setActive={setActive} />
      <ContentTitle title={active.label} />
      <div className='content__body container'>
        {active.id === 0 ? (
          <ITDevEng />
        ) : active.id === 1 ? (
          <DroneDevEng />
        ) : active.id === 2 ? (
          <BidAgencyEng />
        ) : active.id === 3 ? (
          <SuppliyStoreEng />
        ) : active.id === 4 ? (
          <ResearchSerEng />
        ) : active.id === 5 ? (
          <ConsortiumEdEng />
        ) : (
          <OilAnalysisEng />
        )}
      </div>
    </div>
  );
};

export default BusinessEng;
