import React, { useEffect, useRef, useState } from 'react';
import '../scss/layout/Header.scss';
import { Link, useLocation } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoIosClose } from 'react-icons/io';

const stickyurl =
  'https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/companyLogo/sdenet.png';
const topurl =
  'https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/%C3%AC%C2%97%C2%90%C3%AC%C2%8A%C2%A4%C3%AB%C2%8D%C2%B0%C3%AB%C2%84%C2%B7-%C3%AC%C2%B5%C2%9C%C3%AC%C2%A2%C2%85_333_4_-removebg-preview-1691562997870.png';

const ENG = 'eng';

const Header = () => {
  const path = useLocation();
  const [scroll, setScroll] = useState('top');
  const [isMenuOpen, setIsMenuOpen] = useState(false); // mobile 메뉴 상태 추가
  const navRef = useRef(); // 모바일 nav Ref
  const engPath = path.pathname.split('/')[1]; // eng

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setScroll('top');
    } else {
      setScroll('sticky');
    }
  };

  // 모바일 탭 메뉴 외부 클릭시 닫기
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isMenuOpen && navRef.current && !navRef.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (path.pathname !== '/' && path.pathname !== '/eng') {
      setScroll('sticky');
      window.removeEventListener('scroll', handleScroll);
      return;
    } else {
      setScroll('top');
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll); //clean up
    };
  }, [path.pathname]);
  // 홈의 스크롤이 탑에 있을 때 투명

  return (
    <header id='header' className={`mainheader ${scroll}`}>
      <div id='header-wrap'>
        <div id='logo'>
          <Link
            to='/'
            id='sdenetLogoImg'
            className='sdenetLogoImg'
            data-dark-logo='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/companyLogo/sdenet.png'>
            <img
              src={scroll === 'top' ? topurl : stickyurl}
              alt='SDENET Logo'
            />
          </Link>
        </div>
        <nav className={`mainnav `}>
          <ul className='mainnav__box'>
            <li>
              <Link
                to={engPath === ENG ? '/eng' : '/'}
                className='mainnav__menulink'>
                {engPath === ENG ? 'HOME' : '홈'}
              </Link>
            </li>
            {/* <li>
              <Link
                to={engPath === ENG ? '/eng/company' : '/company'}
                className='mainnav__menulink'>
                {engPath === ENG ? 'ABOUT US' : '회사소개'}
              </Link>
            </li> */}
            <li>
              <Link
                to={engPath === ENG ? '/eng/business' : '/business'}
                className='mainnav__menulink'>
                {engPath === ENG ? 'BUSINESS AREAS' : '사업분야'}
              </Link>
            </li>
            <li>
              <Link
                to={engPath === ENG ? '/eng/recruit' : '/recruit'}
                className='mainnav__menulink'>
                {engPath === ENG ? 'RECRUIT' : '채용정보'}
              </Link>
            </li>
            {/* <li>
              <Link
                to={engPath === ENG ? '/eng/information' : '/information'}
                className='mainnav__menulink'>
                {engPath === ENG ? 'INFORMATION' : '홍보관'}
              </Link>
            </li> */}
            {/* <li>
              <Link
                to={
                  engPath === ENG ? '/eng/customerSupport' : '/customerSupport'
                }
                className='mainnav__menulink'>
                {engPath === ENG ? 'SUPPORT' : '고객지원'}
              </Link>
            </li> */}
            <li>
              <Link to='/' className='mainnav__menulink'>
                KOR
              </Link>
              <span>/</span>
              <Link to='/eng' className='mainnav__menulink'>
                ENG
              </Link>
            </li>
          </ul>
        </nav>

        {/* 모바일 메뉴 */}
        <GiHamburgerMenu
          className={`hamburger-menu ${scroll}`}
          onClick={toggleMenu}
          size={24}
        />
        {/* 모바일 nav */}
        <nav
          ref={navRef}
          className={`mobilemainnav ${isMenuOpen ? 'open' : ''}`}>
          <div className='mobilemainnav__navCloseBtn'>
            <IoIosClose size={30} onClick={toggleMenu} />
          </div>

          <ul className='mobilemainnav__box'>
            <li>
              <Link
                to={engPath === ENG ? '/eng' : '/'}
                className='mobilemainnav__menulink'
                onClick={toggleMenu}>
                {engPath === ENG ? 'HOME' : '홈'}
              </Link>
            </li>
            {/* <li>
              <Link
                to={engPath === ENG ? '/eng/company' : '/company'}
                className='mobilemainnav__menulink'
                onClick={toggleMenu}>
                {engPath === ENG ? 'ABOUT US' : '회사소개'}
              </Link>
            </li> */}
            <li>
              <Link
                to={engPath === ENG ? '/eng/business' : '/business'}
                className='mobilemainnav__menulink'
                onClick={toggleMenu}>
                {engPath === ENG ? 'BUSINESS AREAS' : '사업분야'}
              </Link>
            </li>
            <li>
              <Link
                to={engPath === ENG ? '/eng/recruit' : '/recruit'}
                className='mobilemainnav__menulink'
                onClick={toggleMenu}>
                {engPath === ENG ? 'RECRUIT' : '채용정보'}
              </Link>
            </li>
            {/* <li>
              <Link
                to={engPath === ENG ? '/eng/information' : '/information'}
                className='mobilemainnav__menulink'
                onClick={toggleMenu}>
                {engPath === ENG ? 'INFORMATION' : '홍보관'}
              </Link>
            </li> */}
            {/* <li>
              <Link
                to={
                  engPath === ENG ? '/eng/customerSupport' : '/customerSupport'
                }
                className='mobilemainnav__menulink'
                onClick={toggleMenu}>
                {engPath === ENG ? 'SUPPORT' : '고객지원'}
              </Link>
            </li> */}
            <li>
              <Link
                to='/'
                className='mobilemainnav__menulink'
                onClick={toggleMenu}>
                KOR
              </Link>
              <span>/</span>
              <Link
                to='/eng'
                className='mobilemainnav__menulink'
                onClick={toggleMenu}>
                ENG
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
