import React from 'react';
import ContentTitle from '../components/ContentTitle';
import '../scss/page/Recruit.scss';
import {
  SiAmazonaws,
  SiAmazonec2,
  SiGitlab,
  SiJavascript,
  SiMongodb,
  SiNotion,
  SiPostgresql,
  SiReact,
  SiSwagger,
  SiTypescript,
  SiNextdotjs,
  SiTailwindcss,
  SiNestjs,
} from 'react-icons/si';
import { LiaNode } from 'react-icons/lia';

const Recruit = () => {
  return (
    <div className='recruit pagemargin'>
      <ContentTitle title='채용정보' />
      <div className='content__body container'>
        <div className='recruit__textBox'>
          <h5>인재상</h5>
        </div>
        <div className='recruit__imgBox'>
          <img
            src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/ideal-talent.jpg'
            alt='idealTalent'
          />
        </div>
        <div className='recruit__textBox'>
          <h5>기술스택</h5>
        </div>

        <div className='recruit__stackBox'>
          <div className='recruit__stackBox__itemBox'>
            <p>사용언어</p>
            <div>
              <div>
                <SiJavascript size={40} fill='#fad014' />
                <span>JavaScript</span>
              </div>

              <div>
                <SiTypescript size={40} fill='#2F74C0' />
                <span>TypeScript</span>
              </div>
            </div>
          </div>

          <div className='recruit__stackBox__itemBox'>
            <p>프론트엔드</p>
            <div>
              <div>
                <SiReact size={45} fill='#00BFFF' />
                <span>React.js</span>
              </div>

              <div>
                <SiNextdotjs size={45} fill='#000000' />
                <span>Next.js</span>
              </div>

              <div>
                <SiTailwindcss size={45} fill='#36B7F0' />
                <span>TailwindCSS</span>
              </div>

              <div>
                <img
                  src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/tanstackquery.png'
                  alt='tanstackquery'
                  style={{ width: '45px', marginRight: '5px' }}
                />
                <span>Tanstack Query</span>
              </div>

              <div>
                <span>
                  <img
                    src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/zustand.png'
                    alt='zustand'
                    style={{ width: '45px', marginRight: '5px' }}
                  />
                  Zustand
                </span>
              </div>
            </div>
          </div>

          <div className='recruit__stackBox__itemBox'>
            <p>백엔드</p>
            <div>
              <div>
                <LiaNode size={50} fill='#27c427' />
                <span>Node.js</span>
              </div>

              <div>
                <SiNestjs size={50} fill='#D9224C' />
                <span>Nest.js</span>
              </div>

              <div>
                <SiSwagger size={45} fill='	#6B8E23' />
                <span>Swagger</span>
              </div>
            </div>
          </div>

          <div className='recruit__stackBox__itemBox'>
            <p>데이터 베이스</p>
            <div>
              <div>
                <SiMongodb size={45} fill='#008000' />
                <span>MongoDB</span>
              </div>

              <div>
                <img
                  src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/mysql.png'
                  alt='mysql'
                  style={{ width: '50px', marginRight: '5px' }}
                />
                <span>MySQL</span>
              </div>

              <div>
                <SiPostgresql size={45} fill='#4682B4' />
                <span>PostgreSQL</span>
              </div>
            </div>
          </div>

          <div className='recruit__stackBox__itemBox'>
            <p>데브옵스</p>
            <div>
              <div>
                <SiAmazonaws size={45} fill='#FFA500' />
                <span>AWS</span>
              </div>

              <div>
                <img
                  src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/awsAmplify.png'
                  alt='AWS Amplify'
                  style={{ width: '50px', marginRight: '5px' }}
                />
                <span>AWS Amplify</span>
              </div>

              <div>
                <SiAmazonec2 size={40} fill='#FF8C00' />
                <span>Amazon EC2</span>
              </div>

              <div>
                <SiGitlab size={40} fill='#ff652e' />
                <span>GitLab</span>
              </div>
            </div>
          </div>

          <div className='recruit__stackBox__itemBox'>
            <p>협업툴</p>
            <div>
              <div>
                <SiNotion size={40} />
                <span>Notion</span>
              </div>

              <div>
                <img
                  src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/gather.png'
                  alt='gather'
                  style={{ width: '45px', marginRight: '5px' }}
                />
                <span>Gather</span>
              </div>

              <div>
                <img
                  src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/slack.png'
                  alt='slack'
                  style={{ width: '45px', marginRight: '5px' }}
                />
                <span>Slack</span>
              </div>
            </div>
          </div>
        </div>

        <div className='recruit__textBox'>
          <h5>채용과정</h5>
          <span>
            *입사지원 서류에 허위사실이 발견될 경우, 채용확정 이후라도 채용이
            취소될 수 있습니다.
          </span>
          <span>*채용절차는 회사 사정에 따라 변경될 수 있습니다.</span>
        </div>
        <div className='recruit__stepBox'>
          <img
            alt='recste'
            src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/Recruitment+procedure.jpg'
          />
        </div>
      </div>
    </div>
  );
};

export default Recruit;
