import React from 'react';
import '../scss/components/ContentTitle.scss';

const ContentTitle = ({ title }) => {
  return (
    <section className='content__title container'>
      <h2>{title}</h2>
    </section>
  );
};

export default ContentTitle;
