import React from 'react';
import '../../scss/page/Business.scss';

const SuppliyStoreEng = () => {
  return (
    <div className='suppliyStore'>
      <div className='textBox'>
        <h5>
          We supply various ship supplies and carry out shipping agency work.
        </h5>
        <span>
          Purchasing and shipping the ship supplies desired by the customer
          <br />- Ship supplies are a general term for items commonly used on
          ships. It is also expressed as a ship's goods, and in the dictionary
          meaning, it is used in ships. It can be defined as a commodity.
          However, ships are used for cargo ships, passenger ships, etc. Because
          various items are being used according to the Therefore, it is
          difficult to individually define whether or not it is a good product.
        </span>
      </div>
      <div className='videoBox'>
        <video
          id='contents-video'
          poster='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/SuppliyShip.mp4'
          preload='auto'
          loop
          autoPlay
          muted>
          <source
            src='https://sdenet-rulebook-storage.s3.ap-northeast-2.amazonaws.com/website/SuppliyShip.mp4'
            type='video/mp4'
          />
        </video>
      </div>
    </div>
  );
};

export default SuppliyStoreEng;
